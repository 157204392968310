<template>
    <div class="date-filters">
        <div class="date-filters__title">
            <v-icon small>fa fa-calendar-alt</v-icon>
            <p class="mb-0">{{ $props.title }}</p>
        </div>

        <v-radio-group class="date-filters__periods" v-model="period" :disabled="disabled" hide-details>
            <v-radio
                v-for="({ label, value }) in periods" :key="value"
                :label="label"
                :value="value"
                :color="$props.color"
            />
        </v-radio-group>

        <v-expansion-panels class="date-filters__custom-range" v-model="expansionPanel" :disabled="disabled" focusable flat tile>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Custom Date Range
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <QDatePeriodPicker
                        v-model="filters.startDate"
                        :allowed-dates="allowedDates.startDate"
                        label="Start"
                        :color="$props.color"
						:disabled="disabled"
                    />
                    <QDatePeriodPicker
                        v-model="filters.endDate"
                        :allowed-dates="allowedDates.endDate"
                        label="End"
                        :color="$props.color"
						:disabled="disabled"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import moment from 'moment'
import QDatePeriodPicker from '@/components/utils/QDatePeriodPicker.vue'

export default {
    components: { QDatePeriodPicker },
    props: {
        value: { type: Object, default: () => ({}) },
        lastDaysPeriods: { type: Array, default: () => [7, 30, 60, 90]},
        title: { type: String, default: 'Time Period' },
        color: { type: String, default: 'primary' },
        disabled: { type: Boolean, default: false },
    },
    data () {
        return {
            filters: {
                startDate: null, // YYYY-MM-DD
                endDate: null, // YYYY-MM-DD
            },
            expansionPanel: null,
            period: null,
        }
    },
    created () {
        this.resetFilters()
    },
    computed: {
        periods () {
            return [
                ...this.lastDaysPeriods.map(days => ({
                    label: `Last ${days} Days`,
                    value: this.getLastDaysPeriod(days),
                })),
                { label: 'YTD', value: 'YTD' },
                { label: 'All', value: 'all' },
            ]
        },
        periodsCalculated () {
            let period = 'custom'
            if (!this.filters.startDate && !this.filters.endDate) { period = 'all' }
            if (this.filters.startDate && this.filters.endDate) {
                const startDate = moment(this.filters.startDate, 'YYYY-MM-DD')
                const endDate = moment(this.filters.endDate, 'YYYY-MM-DD')
                const endDateIsToday = endDate.isSame(moment(), 'day')
                const startDateIsStartOfYear = startDate.isSame(moment().startOf('year'), 'day')
                if (endDateIsToday) {
                    if (startDateIsStartOfYear) {
                        period = 'YTD'
                    } else {
                        const lastDaysPeriod = endDate.diff(startDate, 'days') + 1
                        if (this.lastDaysPeriods.includes(lastDaysPeriod)) {
                            period = this.getLastDaysPeriod(lastDaysPeriod)
                        }
                    }
                }
            }
            return period
        },
        allowedDates () {
            let startDateAllowed = null
            let endDateAllowed = null
            if (this.filters.startDate) {
                endDateAllowed = (val) => moment(val, 'YYYY-MM-DD').isSameOrAfter(moment(this.filters.startDate, 'YYYY-MM-DD'), 'day')
            }
            if (this.filters.endDate) {
                startDateAllowed = (val) => moment(val, 'YYYY-MM-DD').isSameOrBefore(moment(this.filters.endDate, 'YYYY-MM-DD'), 'day')
            }
            return {
                startDate: startDateAllowed,
                endDate: endDateAllowed,
            }
        },
    },
    watch: {
        value: {
            deep: true,
            handler () { this.resetFilters() },
        },
        period () {
            this.calculateFilters()
        },
        filters: {
            deep: true,
            handler () { this.updateFilters() },
        },
    },
    methods: {
        updateFilters () {
            this.$emit('input', { ...this.filters })
        },
        resetFilters () {
            this.filters.startDate = this.value.startDate || null
            this.filters.endDate = this.value.endDate || null
            this.updatePeriods()
            this.checkOpenPanel()
        },
        calculateFilters () {
            if (!this.period) { return }
            if (this.period === 'all') {
                this.filters.startDate = null
                this.filters.endDate = null
            } else {
                this.filters.endDate = moment().format('YYYY-MM-DD')
                if (this.period.startsWith('last')) {
                    const days = this.getPeriodDays(this.period) - 1
                    this.filters.startDate = moment().subtract(days, 'days').format('YYYY-MM-DD')
                }
                if (this.period === 'YTD') {
                    this.filters.startDate = moment().startOf('year').format('YYYY-MM-DD')
                }
            }
        },
        updatePeriods () {
            this.period = this.periodsCalculated === 'custom' ? null : this.periodsCalculated
        },
        checkOpenPanel () {
            if (this.periodsCalculated === 'custom') {
                this.expansionPanel = 0
            }
        },
        getPeriodDays (lastDays) {
            const days = lastDays?.replace('last', '')
            return Number(days)
        },
        getLastDaysPeriod (days) {
            return `last${days}`
        },
    },
}
</script>

<style lang="scss">
.date-filters {
    $padding: 0.75rem;
    &__title {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        .v-icon {
            color: inherit;
        }
    }
    &__periods {
        padding-left: $padding;
        padding-right: $padding;
    }
    &__custom-range {
        margin-top: 0.25rem;
        .v-expansion-panel {
            background-color: transparent !important;
        }
        .v-expansion-panel-header {
            padding: 0.75rem calc(#{$padding} + 2px);
            min-height: unset;
            font-size: 1rem;
            line-height: 1.3;
        }
        .v-expansion-panel-header__icon {
            padding-left: 1rem;
        }
        .v-expansion-panel-content__wrap {
            padding: 0 $padding 1.5rem;
        }
    }
}
</style>
