<template>
    <v-menu
        v-model="doShowDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y :nudge-top="-5" max-width="290px" min-width="290px"
    >
        <template #activator="bind">
            <v-text-field
                :value="dateFormatted"
                :color="$props.color"
                clearable
                hide-details
                readonly
                class="mt-2"
                v-bind="{ ...$attrs, ...bind.attrs }"
                @click:clear="clearDate"
                @click="showDatePicker"
                @keydown.enter="showDatePicker"
            />
        </template>

        <v-date-picker
            v-model="date"
            :allowed-dates="$props.allowedDates"
            :color="$props.color"
            no-title
            @input="update"
        />
    </v-menu>
</template>

<script>
import moment from 'moment'

export default {
    inheritAttrs: false,
    props: {
        value: { type: String }, // 'YYYY-MM-DD'
        allowedDates: { type: [Object, Function], default: null },
        rules: { type: Array, default: () => ([]) },
        color: { type: String },
        dateFormat: { type: String, default: 'MM/DD/YY' },
    },
    data () {
        return {
            date: this.value,
            dateFormatted: this.formatValue(this.value),
            doShowDatePicker: false,
        }
    },
    watch: {
        value (value) {
            this.date = this.value
            this.dateFormatted = this.formatValue(value)
        },
    },
    methods: {
        showDatePicker () {
            this.date = this.value
            this.doShowDatePicker = true
        },
        clearDate () {
            this.$emit('input', null)
        },
        formatValue (value) {
            if (!value) { return null }
            if (!moment(value).isValid()) { return null }
            return moment(value).format(this.dateFormat)
        },
        update (value) {
            this.$emit('input', value || null)
            this.doShowDatePicker = false
        },
    },
}
</script>
